import {validatorEmail, validatorHayeren, validatorPhone, validatorRequired, validatorSocCard} from "./validators";

export var userFields={
    userFields: [
        {
            key: 'lastname',
            label: 'Ազգանուն',
            validation: {
                ...validatorRequired,
                ...validatorHayeren,
            }
        },
        {
            key: 'firstname',
            label: 'Անուն',
            validation: {
                ...validatorRequired,
                ...validatorHayeren,
            }
        },
        {
            key: 'middlename',
            label: 'Հայրանուն',
            validation: {
                ...validatorRequired,
                ...validatorHayeren,
            }
        },
        {
            key: 'mob_phone',
            label: 'Բջջ. հեռախոսի համար',
            validation: {
                ...validatorRequired,
                ...validatorPhone,
                validate:(e)=>{

                }
            },
        },
        {
            key: 'email',
            label: 'Էլ. փոստ',
            validation: {
                ...validatorRequired,
                ...validatorEmail,
            }
        },
    ],
    passportFields: [
        {
            key: 'soc_card',
            label: 'Սոց քարտ / Տեղեկանք / ՀԾՀ',
            type: 'masked',
            validation: {
                ...validatorRequired,
                ...validatorSocCard,
            },
            mask: '9999999999'
        },
        {
            key: 'doc_type',
            label: 'Փաստաթղթի տեսակ',
            type: 'list',
            validation: {
                ...validatorRequired,
            },
        },
        {
            key: 'doc_num',
            label: 'Փաստաթղթի սերիա/համար',
            size:9,
            validation: {
            },
        },
        {
            key: 'doc_date',
            label: 'Տրված է',
            type: 'masked',
            mask: '99/99/9999',
            validation: {
            },
        },
        {
            key: 'doc_region_num',
            label: 'Ում կողմից',
            type: 'masked',
            mask: '999',
            validation: {
                ...validatorRequired,
                pattern: {
                    value: /[0-9]{3}/,
                    message: "Պետք է լրացնել 3 նիշ",
                },
            }
        },
        {
            key: 'doc_valid_date',
            label: 'Վավերական է մինչև',
            type: 'masked',
            mask: '99/99/9999',
            events: {
            },
            validation: {
            },
        },
        {
            key: 'dob',
            label: 'Ծննդյան օր',
            type: 'masked',
            mask: '99/99/9999',
            validation: {
            },
        },
    ]
}
