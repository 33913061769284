import React, {useEffect, useRef} from 'react'
import { useParams } from 'react-router'
import About from '../components/About'
import Advantages from "../components/Advantages"
import HomeBanner from '../components/HomeBanner'
import HomeCalculator from '../components/HomeCalculator'

export default function Home() {
  const aboutSection = useRef();
  const {section} = useParams();

  let pageWidth = window.innerWidth


    useEffect(() => {
      if(section==='about'){
        window.scrollTo({
          top: aboutSection.current.offsetTop,
          behavior: 'smooth',
        });
      }
    },[section])

  return (
    <div className='container'>
      <HomeBanner />
      <HomeCalculator />
      <Advantages />
      <section ref={aboutSection}>
        <About />
      </section>
    </div>
  )
}
