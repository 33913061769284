export class Auth{
    static data = null;
    static isGuest=()=>{
       let userData = Auth.getAuthData();
       return userData===null;
    }
    static getAuthData(){
        if(Auth.data){
            return Auth.data;
        }
        try{
            Auth.data= JSON.parse(localStorage.getItem('authData'));
        }catch (e){
            Auth.data= null;
        }
        return Auth.data;
    }
    static setAuthData(data){
        Auth.data=data;
        localStorage.setItem('authData',JSON.stringify(data));
        localStorage.setItem('name',data.fname);

    }
    static getUserData(){
        let authData=Auth.getAuthData();
        if(authData && authData.hasOwnProperty("userData")){
            return authData.userData;
        }
        return null;
    }
    static getToken(){
        let authData=Auth.getAuthData();
        if(authData && authData.hasOwnProperty("token")){
            return authData.token;
        }
        return null;
    }
    static login = (data)=>{
        Auth.setAuthData(data);
        // window.resetMenuItems();
    }
    static logout = () => {
        Auth.data = null;
        localStorage.setItem('authData',null);
        window.reload();
        Auth.isGuest()
    }
}