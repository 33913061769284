import {Auth} from "./Auth";

export class Api{
    static BASE_URL = `${process.env.REACT_APP_BACKEND}`
    static post(action,postBody){
        return Api.request(action,postBody);
    }
    static get(action,params=[],getBody=null){
        let get_params=[];
        for(let i in params){
            get_params.push(i+'='+params[i])
        }
        if(get_params.length){
            action+='?'+get_params.join("&");
        }
        return Api.request(action,getBody,'GET');
    }
    static request(action,postBody,method="POST"){
        const requestUrl = Api.BASE_URL+action;
        const isFormData=(postBody instanceof FormData);
        let headers={} ;
        if(!isFormData){
            headers['Content-Type']='application/json';
        }
        let token =  Auth.getToken();
        if(token){
            headers['Authorization']="Bearer "+token
        }
        const requestMetadata = {
            method: method,
            headers: headers,
        };
        if(postBody){
            if(isFormData){
                requestMetadata["body"] = postBody;
            }else{
                requestMetadata["body"] = JSON.stringify(postBody);
            }
        }
        return new Promise( async (resolve,reject)=>{
            let a = await fetch(requestUrl, requestMetadata).then(res => res.json(),(data)=>{
                window.popupSetter({open:true,body:"Ինչ որ բան սխալ է"})
            });
            if(a.respcode===1){
                resolve(a.data);
            }else if(a.respcode===2){
                resolve(a);
            }else if(a.respcode === -1){
                resolve([]);
            }
            else{
                if(a.status===401){
                    Api.refreshToken().then((data)=>{
                        if(data){
                           return Api.request(action,postBody,method);
                        }
                    });
                }
                // window.popupSetter({open:true,body:a.msg,},)
                reject(a.msg);
            }
        })
    }
    
    static refreshToken(){
        let authData = Auth.getAuthData();
        if(authData && authData.hasOwnProperty("refresh_token")){
            return Api.post('auth/refresh-token',{refresh_token:authData.refreshToken}).then((data)=>{
                if(data.token){
                   authData.token = data.token;
                   Auth.setAuthData(authData);
                    return true;
                }
            },(data)=>{
                return false;
            })
        }
        
    }
}
